module.exports = {
    'title': 'メール配信サービスの管理',
    'label_email': 'Eメールアドレス',
    'label_unsub_choice': '選択：',
    'label_unsub_marketing': 'プロモーションメールの配信を停止する',
    'label_unsub_monthly': '月間アカウント概要の配信を停止する',
    'label_btn_submit': '配信停止',
    'label_btn_redirect': 'ホームへ戻る',
    'notification_success_title': '配信停止の確認',
    'label_success_email': ' への、以下のメール配信を停止しました。',
    'bullet_ayana_rewards_promotion': 'アヤナリワードプロモーション',
    'bullet_ayana_rewards_monthly': 'アヤナリワード月間アカウント概要',
    'label_success_resubscribe': '再配信をご希望の場合はプロフィールページからご登録いただけます。',
    'notification_error_title': 'エラー'
}